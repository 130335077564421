import React, {useState} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {Button, DatePicker, Spin, Select, Alert} from 'antd';
import {useOutlet} from 'reconnect.js';

import {REPORT_TYPE} from '../dictionary';

export default function ReportPage({}) {
  const [profile] = useOutlet('user');
  const [actions] = useOutlet('actions');
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [filters, setFilters] = useState({
    // 資料區間
    start_date: '',
    end_date: '',
    // 報表類型
    type: null,
  });

  if (!profile) {
    return null;
  }

  const handleExport = async () => {
    try {
      await actions.exportReport(filters);
      setAlert({
        message: '匯出報表成功',
        type: 'success',
      });
    } catch (error) {
      let errorMessage = '';
      if (error.errors.type) {
        const errorType = REPORT_TYPE[filters.type];
        errorMessage = `${errorType}不是有效的選擇。`;
      } else if (error.errors.start_date) {
        errorMessage = '起始日期為必需欄位。';
      } else if (error.errors.end_date) {
        errorMessage = '終止日期為必需欄位。';
      }
      setAlert({
        message: `匯出報表失敗(${errorMessage})`,
        type: 'warning',
      });
    }
  };

  return (
    <Spin spinning={loading}>
      <Wrapper>
        <Block>
          <Title>報表中心</Title>
          {alert && (
            <Alert message={alert.message} type={alert.type} showIcon />
          )}
          <Description>
            此模組匯出報表將寄發至管理員 email
            信箱中，倘若匯出資料區間較大或筆數較多，請於匯出後等待一段時間再去
            email 信箱中查看
          </Description>
          <InputGroup>
            <InputContainer>
              <InputLabel>資料區間</InputLabel>
              <DatePicker.RangePicker
                style={{width: 400}}
                placeholder={['起始日期', '終止日期']}
                value={[
                  filters.start_date ? moment(filters.start_date) : null,
                  filters.end_date ? moment(filters.end_date) : null,
                ]}
                ranges={{
                  本日: [moment(), moment()],
                  本月: [moment().startOf('month'), moment().endOf('month')],
                }}
                onCalendarChange={(val) => {
                  const start = moment(val[0]).format('YYYY-MM-DD');
                  const end = moment(val[1]).format('YYYY-MM-DD');
                  setFilters({
                    ...filters,
                    start_date: start,
                    end_date: end,
                  });
                }}
                onOpenChange={(open) => {
                  if (open) {
                    setFilters({
                      ...filters,
                      start_date: '',
                      end_date: '',
                    });
                  }
                }}
              />
            </InputContainer>
            <InputContainer>
              <InputLabel>報表類型</InputLabel>
              <Select
                style={{width: 400}}
                placeholder="點擊展開下拉式選單，選擇欲匯出的報表類型"
                value={filters.type}
                onChange={(value) => {
                  setFilters({...filters, type: value});
                }}>
                {Object.keys(REPORT_TYPE).map((key) => (
                  <Select.Option key={key} value={key}>
                    {REPORT_TYPE[key].label}
                  </Select.Option>
                ))}
              </Select>
            </InputContainer>
            {filters.type && <Info>{REPORT_TYPE[filters.type].info}</Info>}
          </InputGroup>
          <ButtonContainer>
            <Button
              type="primary"
              style={{
                width: 80,
                height: 48,
                fontSize: 16,
                fontWeight: 500,
                borderRadius: 10,
              }}
              disabled={
                !filters.start_date || !filters.end_date || !filters.type
              }
              onClick={() => {
                setLoading(true);
                handleExport();
                setLoading(false);
              }}>
              匯出
            </Button>
          </ButtonContainer>
        </Block>
      </Wrapper>
    </Spin>
  );
}

const Wrapper = styled.div`
  margin: 20px;

  .ant-alert {
    border-radius: 8px;
  }

  .ant-alert-success {
    & .ant-alert-message {
      color: #34ae5d;
      font-weight: 500;
    }
  }

  .ant-alert-warning {
    border: none;
    background-color: #fef6f6;

    & .ant-alert-icon {
      color: #f04438;
    }

    & .ant-alert-message {
      color: #f04438;
      font-weight: 500;
    }
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0 0;
  margin-bottom: 16px;
  padding: 25px;
  background-color: #ffffff;
`;

const Title = styled.div`
  color: #000;
  font-size: 20px;
  font-weight: 600;
`;

const Description = styled.div`
  color: #40444c;
  font-size: 14px;
  font-weight: 400;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  margin-bottom: 4px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const InputLabel = styled.span`
  color: #9b9b9b;
  font-size: 14px;
  font-weight: 500;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const Info = styled.div`
  color: #9b9b9b;
  font-size: 14px;
  font-weight: 400;
`;
